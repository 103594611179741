import './list.scss';
import dataApi from '@/api/manage/guestInfo.js';
import dataBankApi from '@/api/manage/bank';
import dataMemberApi from '@/api/manage/member';
import dataProductApi from '@/api/manage/product';

const dataStatus = [
  {
    label: '进件中',
    value: 1,
    disabled: false
  },
  {
    label: '审核中',
    value: 2,
    disabled: false
  },
  {
    label: '审批拒绝',
    value: 3,
    disabled: false
  },
  {
    label: '授信成功',
    value: 4,
    disabled: false
  },
  {
    label: '佣金登记中',
    value: 5,
    disabled: false
  },
  {
    label: '进件完结',
    value: 6,
    disabled: false
  }
];

export default {
  name: 'guest_info',
  components: {
  },
  data () {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      dialogVisible: false,
      productList: [],
      bankList: [],
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        editBtn: false,
        selection: false,
        delBtn: false,
        addBtn: false,
        column: [
          {
            label: '供应商',
            prop: 'bank_id',
            type: 'table',
            span: 12,
            dialogWidth: '60%',
            search: true,
            hide: true,
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '标识',
                  prop: 'logo',
                  width: 120,
                  type: 'img'
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataBankApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataBankApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            bind: 'bank.title',
            props: {
              label: 'name',
              value: 'id'
            },
            rules: [
              {
                required: true,
                message: '请选择供应商',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '贷款产品',
            prop: 'product_id',
            type: 'table',
            span: 24,
            search: true,
            hide: true,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataProductApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataProductApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'title',
              value: 'id'
            },
            bind: 'product.title',
            rules: [
              {
                required: true,
                message: '请选择产品',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '供应商',
            prop: 'bank',
            type: 'input',
            detail: true,
            bind: 'bank.title'
          },
          {
            label: '贷款产品',
            prop: 'product',
            type: 'input',
            detail: true,
            bind: 'product.title'
          },
          {
            label: '公司名称',
            prop: 'company',
            type: 'input',
            detail: true,
            search: true
          },
          {
            label: '贷款人',
            prop: 'name',
            type: 'input',
            detail: true,
            search: true
          },
          {
            label: '联系电话',
            prop: 'mobile',
            type: 'input',
            detail: true,
            search: true
          },
          {
            label: '推荐人',
            prop: 'referrer_name',
            type: 'input',
            detail: true
          },
          {
            label: '推荐人',
            prop: 'referrer',
            type: 'table',
            span: 12,
            dialogWidth: '60%',
            dataType: 'number',
            search: true,
            hide: true,
            disabled: true,
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '会员名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'nickname',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.nickname) return '';
              return `${row.nickname}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataMemberApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              console.log(page, value, data);
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataMemberApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'nickname',
              value: 'id'
            },
            rules: [
              {
                required: true,
                message: '请选择推荐人',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            detail: true,
            dicData: dataStatus,
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 12
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            detail: true,
            prop: 'create_time'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            detail: true,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            detail: true,
            prop: 'update_time'
          },
          {
            label: '创建日期',
            prop: 'datetime',
            hide: true,
            span: 12,
            search: true,
            searchslot: true
          }
        ]
      },
      searchData: {},
      formOp: {
        column: [
          {
            label: '公司名称',
            prop: 'company',
            span: 24,
            disabled: true
          },
          {
            label: '贷款人',
            prop: 'name',
            span: 12,
            disabled: true
          },
          {
            label: '推荐人',
            prop: 'referrer_name',
            span: 12,
            disabled: true
          },
          {
            label: '供应商',
            prop: 'bank_id',
            span: 24,
            formslot: true,
            rules: [{
              required: true,
              message: '请选择供应商',
              trigger: 'blur'
            }]
          },
          {
            label: '贷款产品',
            prop: 'product_id',
            span: 24,
            formslot: true,
            rules: [{
              required: true,
              message: '请选择贷款产品',
              trigger: 'blur'
            }]
          },
          {
            label: '银行预授信',
            prop: 'bank_credit',
            span: 24,
            disabled: false,
            append: '万元'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: dataStatus,
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 24
          }
        ],
        disabled: false,
        clearExclude: ['status', 'bank_id', 'product_id', 'referrer', 'mobile'],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center'
      },
      formDa: {},
      defaults: { }
    };
  },
  computed: {},
  mounted () {
  },
  filters: {},
  watch: {
    'formDa.status' (val) {
      console.log('wa', val);
      if (val > 3) {
        this.defaults.product_id.disabled = true;
        this.defaults.bank_id.disabled = true;
        this.defaults.bank_credit.disabled = true;
      } else {
        this.defaults.product_id.disabled = false;
        this.defaults.bank_id.disabled = false;
        this.defaults.bank_credit.disabled = false;
      }
    }
  },
  methods: {
    // 分页
    onLoad (page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange (params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.starttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.endtime = datetime[1];
      }
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset () {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 按条件导出
    searchExcel () {
      const postData = {
        ...this.searchData
      };
      dataApi.exportFile.r(postData).then(res => {
        console.log('接口返回', res);
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data.file;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载'
          });
        });
      });
    },
    // 添加
    addDialogForm (row) {
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.dialogVisible = true;
    },
    dialogCloseCallback () {
      this.productList = [];
      this.bankList = [];
    },
    // 删除
    rowDel (form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.del.r({ id: form.id }).then(res => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate (form, done) {
      dataApi.update.r(form).then(res => {
        console.log(res);
        done(form);
        this.getList();
        this.dialogVisible = false;
      });
      done();
    },
    getInfo (row) {
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.formDa = res.data;
        // 设置product回显
        if (res.data.product) {
          this.productList.push(res.data.product);
        }
        if (res.data.bank) {
          this.bankList.push(res.data.bank);
        }
        // 设置可选状态
        for (let i = 0; i < dataStatus.length; i++) {
          console.log(res.data.status, res.data.status > i || res.data.status >= 3);
          if (res.data.status > i || res.data.status >= 3) {
            dataStatus[i].disabled = true;
          } else {
            dataStatus[i].disabled = false;
          }
        }
        console.log(res.data.status, res.data.status >= 3);
        // 设置是否可编辑
        if (res.data.status >= 3) {
          this.formOp.disabled = true;
          this.formOp.submitBtn = false;
        } else {
          this.formOp.disabled = false;
          this.formOp.submitBtn = true;
        }
      });
    },
    getProduct (query) {
      console.log('query', query);
      if (query !== '') {
        const postData = {
          title: query
        };
        dataProductApi.getList.r(postData).then(res => {
          this.productList = res.data.list;
        });
      }
    },
    productChangeSelect () {

    },
    getBank (query) {
      if (query !== '') {
        const postData = {
          title: query
        };
        dataBankApi.getList.r(postData).then(res => {
          this.bankList = res.data.list;
        });
      }
    },
    bankChangeSelect () {

    },
    // 获取
    getList () {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    // 数据导出
    exportSearchData () {
      // 获取查询条件
    }
  }
};
